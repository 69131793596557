import React from "react"
import CalendarLayout from "../components/CalendarLayout"
import ShowConsultations from "../components/ShowConsultations"

export default function calendar() {
  return (
    <CalendarLayout>
      <ShowConsultations />
    </CalendarLayout>
  )
}
